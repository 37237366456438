import {
  CreateBookingErrorType,
  FormError,
  FormErrorsType,
  GenericErrorType,
  PreviewErrorType,
} from '../../types/errors';
import {
  PlatformMetroServerError,
  PlatformServerError,
} from '../../../__tests__/mocks/ambassador/mockPlatformError';
import { mapToArray } from '../index';

export const getErrorByType = <ErrorType>({
  errorType,
  errors,
}: {
  errorType?: ErrorType;
  errors?: FormError[];
}): FormError =>
  errors?.find((error) =>
    mapToArray<FormErrorsType>(errorType as any).includes(error.errorType),
  )!;

const isErrorOfType = <ErrorType>(errorType: ErrorType, error: FormError) => {
  return mapToArray<FormErrorsType>(errorType as any).some(
    (expectedError) => error.errorType === expectedError,
  );
};

export const hasErrorOfType = <ErrorType>({
  errorType,
  errors,
}: {
  errorType: ErrorType;
  errors: FormError[];
}): boolean => {
  return errors.some((error) => isErrorOfType(errorType, error));
};

export function mapPreviewServerError(
  previewServerError: string,
): PreviewErrorType | GenericErrorType {
  if (previewServerError.includes(PreviewErrorType.INVALID_LINE_ITEM)) {
    return PreviewErrorType.INVALID_LINE_ITEM;
  } else if (
    previewServerError.includes(PreviewErrorType.FAILED_RESOLVING_SERVICE)
  ) {
    return PreviewErrorType.FAILED_RESOLVING_SERVICE;
  } else if (
    previewServerError.includes(
      PreviewErrorType.NUMBER_OF_PARTICIPANTS_NOT_FOUND,
    )
  ) {
    return PreviewErrorType.NUMBER_OF_PARTICIPANTS_NOT_FOUND;
  }
  return GenericErrorType.GENERIC_BOOK_ERROR;
}

export type BookingsServerError = {
  details: {
    applicationError: {
      code: CreateBookingErrorType;
      data?: {
        violations?: [{ description?: string }];
      };
    };
  };
};

export function mapPlatformError(
  error: PlatformServerError | PlatformMetroServerError,
) {
  return (
    (error as PlatformServerError)?.details ||
    (error as PlatformMetroServerError)?.data?.details
  );
}

export function mapCheckoutBookingError(
  error: PlatformServerError | PlatformMetroServerError,
) {
  const errorCode = mapPlatformError(error)?.applicationError?.code;
  if (
    isErrorOfType(CreateBookingErrorType, {
      errorType: errorCode as FormErrorsType,
    })
  ) {
    return errorCode;
  } else {
    return GenericErrorType.GENERIC_BOOK_ERROR;
  }
}
