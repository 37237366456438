import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { resolveURLData } from './URLAdapter/URLDataResolver';
import { getQueryValueByName, QueryParamName } from './URLAdapter/URLAdapter';
import { FormPageData } from '../../types/types';
import { FormApi } from '../FormApi';
import {
  mapBookingsToFormSelectedSlot,
  mapPageDataToBookings,
} from '../../utils/mappers/form-selected-slot.mapper';
import { Booking } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';

type BookingsPageData = {
  bookings?: Booking[];
  timezone?: string;
  openSpots?: number;
  totalSpots?: number;
};

export const getPageAPIData = async ({
  flowAPI,
  formApi,
}: {
  flowAPI: ControllerFlowAPI;
  formApi: FormApi;
}): Promise<FormPageData | undefined> => {
  try {
    let bookingsPageData = await getBookingsPageData({
      flowAPI,
      formApi,
    });

    if (!bookingsPageData.bookings) {
      bookingsPageData =
        await getBookingsPageDataByPageData_DEPRECATED_NAVIGATION({
          flowAPI,
          formApi,
        });
    }

    if (bookingsPageData.bookings) {
      return mapBookingsToFormSelectedSlot({
        bookings: bookingsPageData.bookings!,
        openSpots: bookingsPageData.openSpots!,
        totalSpots: bookingsPageData.totalSpots!,
        timezone: bookingsPageData.timezone!,
        experiments: flowAPI.experiments,
      });
    }

    throw new Error('ERROR: failed to infer URL data service type');
  } catch (error) {
    console.error('ERROR: failed to resolve page API data', error);
    return undefined;
  }
};

export const getBookingsPageData = async ({
  formApi,
  flowAPI,
}: {
  formApi: FormApi;
  flowAPI: ControllerFlowAPI;
}): Promise<BookingsPageData> => {
  const {
    controllerConfig: {
      wixCodeApi: {
        location: { query },
      },
    },
  } = flowAPI;
  let bookings: Booking[] | undefined;
  const timezone = getQueryValueByName(query, QueryParamName.TIMEZONE) || '';
  const bookingIds = getQueryValueByName(
    query,
    QueryParamName.BOOKING_IDS,
  )?.split(',');

  if (bookingIds) {
    try {
      bookings = await formApi.queryBookings({ bookingIds });
    } catch (e) {
      console.error(e);
    }
  }

  return {
    bookings,
    timezone,
  };
};

const getBookingsPageDataByPageData_DEPRECATED_NAVIGATION = async ({
  flowAPI,
  formApi,
}: {
  formApi: FormApi;
  flowAPI: ControllerFlowAPI;
}): Promise<BookingsPageData> => {
  // FOR DEPRECATED NAVIGATIONS
  // this is to support old navigation via url or sessions storage
  // the best practice is to set the page data via api and navigate just with the returned id
  const pageData = await resolveURLData({ flowAPI, formApi });
  let bookings: Booking[] | undefined;

  if (pageData) {
    bookings = mapPageDataToBookings(pageData);
  }

  return {
    bookings,
    timezone: pageData?.timezone,
    openSpots: pageData?.slotAvailability?.openSpots,
    totalSpots: pageData?.slotAvailability?.totalSpots,
  };
};
